<template>
    <b-container v-if="form" fluid >
        <b-row>
            <b-col md="12">
                <b-form-group label="Ставка за час, руб.">
                    <b-form-input v-model='form.rate_per_hour'/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col md='12'>
                <b-form-group label="Активен с">
                    <b-form-datepicker
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        :start-weekday="1"
                        locale="ru"
                        placeholder=""
                        v-model="form.valid_from"
                    />
                </b-form-group>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: 'SalaryRatesForm',
    props: {
        value: {type: Object, default: () => null},
        salary: {type: Object, default: () => null}
    },
    computed: {
        isDiff() {
            return JSON.stringify(this.form) !== JSON.stringify(this.value);
        },
    },
    watch: {
        value: {
            deep: true,
            handler() {
                if (this.isDiff) {
                    this.form = structuredClone(this.value);
                }
            }
        },
        form: {
            deep: true,
            handler() {
                if (this.isDiff) {
                    this.$emit('input', this.form);
                }
            }
        }
    },
    data() {
        return {form: null};
    }
}
</script>
