<template>
    <div>
        <errors-bag-list/>

        <b-card class="mb-1">
            <b-card-header class="p-2 text-center">
                <h3>Добавление промежутока стоимости для должности "{{ salary.work_position.name }}"</h3>
            </b-card-header>

            <b-card-body>
                <salary-rates-form v-model="salaryRate" :salary="salary"/>
            </b-card-body>
        </b-card>

        <control-panel>
            <b-button variant="secondary" @click="$router.go( -1 )">Назад</b-button>
            <b-button variant="success" @click='createSalaryRate()'>Создать</b-button>
        </control-panel>
    </div>
</template>

<script>
    import ControlPanel from '@components/_common/ControlPanel';
    import ErrorsBagList from '@components/_common/ErrorsBagList';

    import ErrorsBag from '@utils/errorsBag';
    import { SALARY_ENDPOINT } from '@utils/endpoints';

    import SalaryRatesForm from './SalaryRatesForm';

    const DEFAULT_SALARY_RATE = {
        work_position_id: null,
        rate_per_hour: null,
        valid_from: null
    };

    const DATE_FORMAT = 'YYYY-MM-DD';

    export default {
        props: [ 'id' ],
        name: 'SalaryRatesCreate',
        components: { ControlPanel, ErrorsBagList, SalaryRatesForm },
        computed: {
            isDiff() {
                return this.salaryRate && JSON.stringify( this.salaryRate ) !== JSON.stringify( DEFAULT_SALARY_RATE );
            },
        },
        data() {
            return {
                ErrorsBag,

                salary: {
                    work_position: {},
                },
                salaryRate: null
            };
        },
        methods: {
            createSalaryRate() {
                ErrorsBag.discard();

                let workPositionId = null;
                if ( this.salary ) {
                    workPositionId = this.salary.workPosition.id;
                }

                let params = {
                    rate_per_hour: this.salaryRate.rate_per_hour,
                    work_position: { id: workPositionId },
                    valid_from: this.salaryRate.valid_from
                };

                this.$http
                    .post( SALARY_ENDPOINT + '/rates', params )
                    .then(
                        () => this.$router.push( { name: 'SalaryRatesList' } ),
                        response => ErrorsBag.fill( response.body )
                    );
            },
            load() {
                ErrorsBag.discard();

                let params = { with: [ 'workPosition', 'followingSalaryRates' ] };

                this.$http
                    .get( SALARY_ENDPOINT + '/settings/' + this.id, { params } )
                    .then( response => this.salary = response.data.data, response => ErrorsBag.fill( response.body ) );

            }
        },
        mounted() {
            this.load();
            this.salaryRate = structuredClone(DEFAULT_SALARY_RATE);
        }
    }
</script>
